import { z } from "zod";
import ActiveBtn from "../../../Components/DataTableBtns/ActiveBtn";
import BooleanIcon from "../../../Components/DataTableBtns/BooleanIcon";
import EditBtn from "../../../Components/DataTableBtns/EditBtn";
import ReadPageBtn from "../../../Components/DataTableBtns/ReadPageBtn";
import { ONLINE, OWNER, PHONE, TAXI } from "../../../Constant";
import { civilityObj } from "../../../Constant/objs";
import { fullName } from "../../../helper/fullName";
import { getCodeLabel } from "../../../helper/getOptions";

// Urls
export const API = {
  list: (p) => `/back/client/${p}/users`,
  create: (p) => `/back/client/${p}/user`,
  edit: (p) => `/back/user/${p}`,
  patch: (p) => `/back/user/${p}`,
  get: (p) => `/back/user/${p}`,
};
// Columns
export const COLUMNS = [
  { name: "Full name", selector: "completename" },
  { name: PHONE, selector: "phone" },

  {
    name: ONLINE,
    cell: (row) => <BooleanIcon state={row.online} />,
    button: "true",
  },
  {
    name: OWNER,
    cell: (row) => <BooleanIcon state={row.usr_owner} />,
    button: "true",
  },

  { name: "Created at", selector: "created_at" },

  {
    name: "Active",
    cell: (row) => {
      return row.usr_owner ? null : (
        <ActiveBtn patch={API.patch(row.code)} state={row.active} />
      );
    },
    button: "true",
  },
  {
    name: "Edit",
    cell: (row) => (
      <EditBtn
        read={API.get(row.code)}
        extra={fullName(row.firstname, row.lastname)}
      />
    ),
    button: "true",
  },

  {
    name: TAXI,
    cell: (row) => <ReadPageBtn read={`/taxi/${row.taxi_code}`} />,
    button: "true",
  },
];

// Search
export const SEARCH_ITEMS = [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
];

// Form
export const CREATE_TAXI_DRIVER = (code) => [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
    schema: z.string().min(3, "Ce champ est obligatoire"),
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  civilityObj,
  {
    title: "Phone",
    placeholder: "Phone",
    name: "phone",
    inputForm: "SimpleInput",
    schema: z.string().min(8, {
      message: "Le numéro de téléphone doit comporter au moins 8 chiffres.",
    }),
  },

  {
    title: "Mail",
    placeholder: "Mail",
    name: "mail",
    inputForm: "SimpleInput",
    schema: z.string().min(8, {
      message: "Le numéro de téléphone doit comporter au moins 8 chiffres.",
    }),
  },

  {
    title: "Address",
    placeholder: "Address",
    name: "address",
    inputForm: "SimpleInput",
  },

  {
    title: "Licence",
    placeholder: "licence",
    name: "licence",
    inputForm: "SimpleInput",
  },
  {
    title: "Licence got at",
    placeholder: "licencegotat",
    name: "licenceGotAt",
    inputForm: "DateInput",
  },
  {
    title: "Licence Expire At",
    placeholder: "licenceExpireAt",
    name: "licenceExpireAt",
    inputForm: "DateInput",
  },
  {
    title: "Taxi",
    name: "taxi",
    inputForm: "SelectApiInput",
    optionsApi: `/back/client/${code}/taxis/choices`,
    getOptions: getCodeLabel,
  },
];

export const EDIT_ITEMS_DRIVER = (code) => [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  civilityObj,
  {
    title: "Phone",
    placeholder: "Phone",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "Address",
    placeholder: "Address",
    name: "address",
    inputForm: "SimpleInput",
  },
  {
    title: "Mail",
    placeholder: "Mail",
    name: "mail",
    inputForm: "SimpleInput",
  },

  {
    title: "Licence",
    placeholder: "licence",
    name: "licence",
    inputForm: "SimpleInput",
  },
  {
    title: "Licence got at",
    placeholder: "licencegotat",
    name: "licenceGotAt",
    inputForm: "DateInput",
  },
  {
    title: "Taxi",
    name: "taxi",
    inputForm: "SelectApiInput",
    optionsApi: `/back/client/${code}/taxis/choices`,
    getOptions: getCodeLabel,
  },
];
