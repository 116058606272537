import {
  Accordion,
  Avatar,
  Center,
  Divider,
  Group,
  Indicator,
  Paper,
  Space,
  Text,
} from "@mantine/core";
import React, { Fragment } from "react";
import { IconCar } from "@tabler/icons-react";

import SimpleTable from "ComponentsMantine/SimpleTable";

import {
  CIRCULATION_DATE,
  LICENCE_EXPIRE_AT,
  LICENCE_GOT_AT,
  PLACES,
  SENSOR,
  SERIAL_NUMBER,
  TAXI,
  TAXI_INFO,
} from "Constant";

const TaxiComp = ({ data }) => {
  const {
    licence_got_at,
    licence_expire_at,
    circulation_date,
    taxi_serial_number,
    registration_number,
    model_taxi,
    brand_taxi,
    active_taxi: active,
    sensor,
    places,
  } = data;

  const title = `${brand_taxi} / ${model_taxi} / ${registration_number}`;

  const indicatorProps = active
    ? { color: "green", label: "Active" }
    : { color: "red", label: "Not active" };

  const requiredKeys = [
    { title: TAXI, key: title },

    { title: LICENCE_GOT_AT, key: licence_got_at },
    { title: LICENCE_EXPIRE_AT, key: licence_expire_at },
    { title: CIRCULATION_DATE, key: circulation_date },
    { title: SERIAL_NUMBER, key: taxi_serial_number },

    { title: SENSOR, key: sensor },
    { title: PLACES, key: places },
  ];
  return (
    <Fragment>
      <Accordion
        chevronPosition="left"
        variant="contained"
        defaultValue={["client"]}
      >
        <Accordion.Item value={"client"} key={0}>
          <Center>
            <Accordion.Control>
              <Group wrap="nowrap">
                <Indicator color={indicatorProps.color}>
                  <Avatar radius="xl" size="lg" color="orange">
                    {<IconCar color="black" />}
                  </Avatar>
                </Indicator>

                <Space w="md" />

                <div>
                  <Text size="xl" fw={700}>
                    {title}
                  </Text>
                  <Text size="sm" c="dimmed" fw={400}>
                    {/* {`phone number: ${user_phone} created at: ${created_at}`} */}
                  </Text>
                </div>
              </Group>
            </Accordion.Control>
            {/* <TaxiSettings data={data} /> */}
            <Space w="lg" />
          </Center>

          <Accordion.Panel>
            <Space h="md" />
            <Paper shadow="xs" withBorder p="xl">
              <Divider
                my="xs"
                label={<Text fw={700}> {TAXI_INFO} </Text>}
                labelPosition="center"
              />
              <SimpleTable requiredKeys={requiredKeys} />
            </Paper>
            <Space h="md" />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Fragment>
  );
};

export default TaxiComp;
