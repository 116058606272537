import {
  Accordion,
  Avatar,
  Center,
  Divider,
  Group,
  Indicator,
  Paper,
  Space,
  Text,
} from "@mantine/core";
import React, { Fragment } from "react";
import { IconCar } from "@tabler/icons-react";

import SimpleTable from "ComponentsMantine/SimpleTable";

import { TAXI, TAXI_INFO } from "Constant";

const TaxiComp = ({ data }) => {
  console.log(data);
  const {
    meid,
    imei,
    serial_number,
    model_taximeter,
    brand_taximeter,
    locked,
    active_taximeter: active,
    sticker,
    sticker_got_at,
    sticker_expire_at,
  } = data;

  const title = `${model_taximeter} /${brand_taximeter} /${serial_number}`;

  const indicatorProps = active
    ? { color: "green", label: "Active" }
    : { color: "red", label: "Not active" };

  const requiredKeys = [
    { title: TAXI, key: title },

    { title: "meid", key: meid },
    { title: "imei", key: imei },
    { title: "Loked", key: locked },
    { title: "sticker", key: sticker },

    { title: "sticker_got_at", key: sticker_got_at },
    { title: "sticker_expire_at", key: sticker_expire_at },
  ];
  return (
    <Fragment>
      <Accordion
        chevronPosition="left"
        variant="contained"
        defaultValue={["client"]}
      >
        <Accordion.Item value={"client"} key={0}>
          <Center>
            <Accordion.Control>
              <Group wrap="nowrap">
                <Indicator color={indicatorProps.color}>
                  <Avatar radius="xl" size="lg" color="orange">
                    {<IconCar color="black" />}
                  </Avatar>
                </Indicator>

                <Space w="md" />

                <div>
                  <Text size="xl" fw={700}>
                    {title}
                  </Text>
                  <Text size="sm" c="dimmed" fw={400}>
                    {/* {`phone number: ${user_phone} created at: ${created_at}`} */}
                  </Text>
                </div>
              </Group>
            </Accordion.Control>
            {/* <TaxiSettings data={data} /> */}
            <Space w="lg" />
          </Center>

          <Accordion.Panel>
            <Space h="md" />
            <Paper shadow="xs" withBorder p="xl">
              <Divider
                my="xs"
                label={<Text fw={700}> {TAXI_INFO} </Text>}
                labelPosition="center"
              />
              <SimpleTable requiredKeys={requiredKeys} />
            </Paper>
            <Space h="md" />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Fragment>
  );
};

export default TaxiComp;
