export const DASHBOARD = "Dashboard";
export const USERS = "Users";
export const DRIVERS = "Drivers";
export const POLYGONS = "Polygons";
export const PROFILES = "Profiles";
export const BRANDS = "Brands";
export const HOLIDAYS = "Holidays";
export const TAXIMETERS_IN_PREPARATION = "Taximeters in Preparation";
export const TAXIMETERS_IN_STOCKS = "Taximeters in Stocks";
export const BOXES = "Boxes";
export const IMPRIMANTES = "Imprimantes";
export const BADGE = "Badge";
export const CLIENT = "Client";
export const LIST_OF_USERS = "List of users";
export const SEARCH = "Search";
export const FILTERS = "Filters";
export const LOADING = "Loading";
export const PARAMETER = "Parameter";
export const STOCK = "Stock";
export const COMMERCIAL = "Commercial";
export const REFRESH = "Refresh";
export const QR_CODE = "QR Code";
export const IMPORT = "Import";
export const SEND = "Send";
export const SENDING = "Sending";
export const FINISHED_PRODUCTS_INVENTORY = "Finished Products Inventory";
export const FINISHED_PRODUCTS_STOCKS = "Finished Products Stocks";
export const WORDING = "Wording";
export const LOG_OUT = "Log Out";
export const FP_Inventory = "FP Inventory";
export const CREATE = "Create";
export const UPDATE = "Update";
export const IMPORT_DATA = "Import Data";
export const USERS_LIST = "List of Users";
export const USERS_PAGE = "Users";
export const POLYGONE = "Polygone";
export const POLYGONE_LIST = "List of Polygone";
export const POLYGONE_PAGE = "Polygone";
export const PROFILE = "Profile";
export const PROFILE_LIST = "List of Profile";
export const PROFILE_PAGE = "Profile";
export const BRAND = "Brand";
export const BRAND_LIST = "List of Brand";
export const BRAND_PAGE = "Brand";
export const SERVICES_LIST_LIST = "List of Services";
export const SERVICES_LIST_PAGE = "Services List";
export const TARIFF = "Tariff";
export const FLATFEE = "Flatfee";
export const PARAMETER_LIST = "List of Parameter";
export const TARIFF_LIST = "List of Tariff";
export const TAXIMETERS_IN_PREPARATION_LIST =
  "List of Taximeters in Preparation";
export const TAXIMETERS_IN_PREPARATION_PAGE = "Taximeters in Preparation";
export const TAXIMETERS_IN_STOCKS_LIST = "List of Taximeters in Stocks";
export const TAXIMETERS_IN_STOCKS_PAGE = "Taximeters in Stocks";
export const BOXES_LIST = "List of Boxes";
export const BOXES_PAGE = "Boxes";
export const IMPRIMANTES_LIST = "List of Imprimantes";
export const IMPRIMANTES_PAGE = "Imprimantes";
export const BADGE_LIST = "List of Badge";
export const BADGE_PAGE = "Badge";
export const CLIENT_LIST = "List of Client";
export const CLIENT_PAGE = "Client";
export const SENSOR = "Sensor";
export const SENSOR_LIST = "List of Sensor";
export const SENSOR_PAGE = "Sensor";
export const FIRMWARE = "Firmware";
export const FIRMWARE_LIST = "List of Firmware";
export const FIRMWARE_PAGE = "Firmware";
export const COLLABORATOR = "Collaborator";
export const PRODUCTION = "Production";
export const ADMINISTRATION = "Administration";
export const TAXIS = "Taxis";
export const BILLS = "Bills";
export const EDIT = "Edit";
export const WORK_ORDER = "Work order";
export const PURCHASE_REQUESTS = "Purchase requests";
export const QUERY = "SQL Query";

export const MATERIAL = "Material";
export const STATISTICS = "Statistics";

export const RAW_MATERIAL = "Raw Material";
export const FINISHED_PRODUCTS = "Finished Products";
export const INVENTORY = "Inventory";
export const STOCKS = "Stocks";
export const MODEL = "Model";
export const FAQ = "FAQ";
export const DISTRIBUTORS = "Distributors";
export const PURCHASE_ORDER = "Purchase order";
export const MY_PURCHASE_ORDER = "My purchase order";
export const SALES = "Sales";
export const SHRING = "Sharing";
export const TAXI = "Taxi";
export const PRODUCTS = "Products";
export const PURCHASE_MY_ORDERS = "Purchase my orders";
export const MY_DISTRIBUTORS = "My Distributors";
export const MY_SENSOR = "My Sensors";
export const MY_STYLUS = "My Stylus";
export const MY_TAXIMETERS = "My Taximeters";
export const MY_BADGES = "My Badges";
export const MY_BOXES = "My Boxes";
export const MY_BRACKETS = "My Brackets";
export const MY_FAQ = "My FAQ";
export const MY_FIRMWARES = "My Firmwares";
export const MY_OBD_ADAPTERS = "My OBD Adapters";
export const MY_PRINTERS = "My Printers";
export const MY_SOS_BUTTONS = "My SOS Buttons";
export const MY_SUNSHIELDS = "My Sunshields";
export const MY_LUMINOUS = "My Luminous";
export const MY_USERS = "My Users";
export const MY_DRIVERS = " My drivers";
export const MY_PARAMETER = "My parameter";
export const MY_RAW_MATERIAL_INVENTORY = "My Raw Material Inventory";
export const MY_RAW_MATERIAL_STOCKS = "My Raw Material Stocks";
export const MY_FINISHED_PRODUCTS_INVENTORY = "My Finished Products Inventory";
export const MY_FINISHED_PRODUCTS_STOCKS = "My Finished Products Stocks";
export const MY_POLYGONS = "My Polygons";
export const MY_WORDING = "My Wording";
export const MY_PROFILES = "My Profiles";
export const MY_BRANDS = "My Brands";
export const MY_SERVICES_LIST = "My Services List";
export const MY_HOLIDAYS = "My Holidays";
export const MY_MODEL = "My Model";
export const MY_CLIENT = "My clients";
export const SHEET_OF = "Sheet of";
export const MY_PRODUCTS = "My Products";
export const MY_DELIVERY_FORM = "My Delivery Form";
export const MY_PURCHASEORDER = "Purchase Order";
export const MY_STOCKS = "My Stocks";
export const MY_SALES = "My Sales";
export const MY_MARKETING_PASSAGER = "My Marketing Passager";
export const MY_RESERVATION = "My Reservation";
export const MY_SHARING = "My Sharing";
export const MY_IMPRIMANTES = "My Imprimantes";
export const MY_AGENCIES = "My Agencies";
export const MY_RULES = "My Rules";
export const MY_VALUES = "My Values";
export const MY_TARIFF = "My Tariff";
export const MY_FLATFEE = "My Flatfee";
export const MY_RELAY_SWITCHES = "My relay switches";
export const MY_FEMALE_BRACKET = "My female bracket";

export const MY_TAXIMETER_PRINTER = "My taximeter printer";
export const MY_TAXIMETER_LOCATIONS = "My taximeter location";
export const MY_TAXIMETER_BOXES = "My taximeter boxes";
export const MY_TAXIMETER_NOTEBOOK = "My taximeter notebook";
export const MY_TAXIMETER_TAXIS = "My taximeter taxis";

export const MY_COLLABORATORS = "My collaborators";
export const MY_COLLABORATOR = "My collaborator";

export const MY_CLIENTS = "My clients";
export const MY_TAXIS = "My taxis";
export const MY_BILLS = "My bills";

export const MY_WORK_ORDERS = "My work orders";
export const MY_WORK_ORDER = "My work order";

export const DELIVARY_FORM = "Delivery form";
export const MY_DELIVARY_FORM = "My delivery form";

export const MY_PURCHASE_REQUESTS = "My purchase requests";

export const CREATE_USER = "Create user";
export const EDIT_USER = "Edit user";
export const CREATE_SERVICE_LIST = "Create Service List";
export const EDIT_SERVICE_LIST = "Edit Service List";

export const CREATE_BRAND = "Create Brand";
export const EDIT_BRAND = "Edit Brand";

export const CREATE_CLIENT = "Create Client";
export const EDIT_CLIENT = "Edit Client";

export const CREATE_COLLABORATOR = "Create Collaborator";
export const EDIT_COLLABORATOR = "Edit Collaborator";

export const CREATE_DELIVERY_FORM = "Create Delivery form";
export const EDIT_DELIVERY_FORM = "Edit Delivery form";

export const CREATE_DISTRIBUTOR = "Create Distributor";
export const EDIT_DISTRIBUTOR = "Edit Distributor";

export const CREATE_FAQ = "Create FAQ";
export const EDIT_FAQ = "Edit FAQ";

export const CREATE_FIRMWARE = "Create Firmware";
export const EDIT_FIRMWARE = "Edit Firmware";

export const CREATE_HOLIDAY = "Create Holiday";
export const EDIT_HOLIDAY = "Edit Holiday";

export const CREATE_MODEL = "Create Model";
export const EDIT_MODEL = "Edit Model";

export const CREATE_PARAMETER = "Create Parameter";
export const EDIT_PARAMETER = "Edit Parameter";

export const CREATE_POLYGON = "Create Polygon";
export const EDIT_POLYGON = "Edit Polygon";

export const CREATE_PROFILES = "Create Profiles";
export const EDIT_PROFILES = "Edit Profile";

export const CREATE_PURCHASE_ORDER = "Create Purchase Order";
export const EDIT_PURCHASE_ORDER = "Edit Purchase Order";

export const CREATE_PURCHASE_REQUESTS = "Create Purchase Requests";
export const EDIT_PURCHASE_REQUESTS = "Edit Purchase Request";

export const CREATE_RAW_MATERIAL_INVENTORY = "Create Raw Material Inventory";
export const EDIT_RAW_MATERIAL_INVENTORY = "Edit Raw Material Inventory";

export const CREATE_RAW_MATERIAL_STOCK = "Create Raw Material Stock";
export const EDIT_RAW_MATERIAL_STOCK = "Edit Raw Material Stock";

export const CREATE_SCREEN = "Create Screen";
export const EDIT_SCREEN = "Edit Screen";

export const CREATE_SERVICES_LIST = "Create Services list";
export const EDIT_SERVICES_LIST = "Edit Services list";

export const CREATE_STATISTICS = "Create Statistics";
export const EDIT_STATISTICS = "Edit Statistics";

export const CREATE_STOCK = "Create Stock";
export const EDIT_STOCK = "Edit Stock";

export const CREATE_TARIFF = "Create Tariff";
export const EDIT_TARIFF = "Edit Tariff";

export const CREATE_WORDING = "Create Wording";
export const EDIT_WORDING = "Edit Wording";

export const CREATE_WORK_ORDERS = "Create Work Orders";
export const EDIT_WORK_ORDER = "Edit Work Order";
export const CREATE_FINISHED_PRODUCTS_INVENTORY =
  "Create Finished Products Inventory";
export const EDIT_FINISHED_PRODUCTS_INVENTORY =
  "Edit Finished Products Inventory";
export const CREATE_BADGE = "Create Badge";
export const CREATE_BOXES = "Create Boxes";
export const EDIT_BOXES = "Edit Box";
export const CREATE_BRACKET = "Create Bracket";
export const EDIT_BRACKET = "Edit Bracket";
export const CREATE_FEMALE_BRACKET = "Create Female Bracket";
export const EDIT_FEMALE_BRACKET = "Edit Female Bracket";
export const CREATE_LUMINOUS = "Create Luminous";
export const EDIT_LUMINOUS = "Edit Luminous";
export const CREATE_OBD_ADAPTER = "Create OBD Adapter";
export const EDIT_OBD_ADAPTER = "Edit OBD Adapter";
export const CREATE_PRINTER = "Create Printer";
export const EDIT_PRINTER = "Edit Printer";
export const CREATE_RELAY_SWITCHES = "Create Relay Switches";
export const EDIT_RELAY_SWITCHES = "Edit Relay Switches";
export const CREATE_SENSOR = "Create Sensor";
export const EDIT_SENSOR = "Edit Sensor";
export const CREATE_AGENCIES = "Create Agencies";
export const EDIT_AGENCIES = "Edit Agency";
export const CREATE_TAXIS = "Create Taxis";
export const EDIT_TAXIS = "Edit Taxi";
export const CREATE_DRIVERS = "Create Drivers";
export const EDIT_DRIVERS = "Edit Driver";
export const CREATE_SOS_BUTTONS = "Create SOS Buttons";
export const EDIT_SOS_BUTTONS = "Edit SOS Button";
export const CREATE_STYLUS = "Create Stylus";
export const EDIT_STYLUS = "Edit Stylus";
export const CREATE_SUNSHIELDS = "Create Sunshields";
export const EDIT_SUNSHIELDS = "Edit Sunshield";
export const CREATE_TAXIMETERS = "Create Taximetres";
export const EDIT_TAXIMETERS = "Edit Taximeter";
export const PURCHASE_ORDER_OF_RAW_MATERIAL_STOCK =
  "Purchase order of raw material stocks";
export const CREATE_FLATFEE = "Create Flatfee ";
export const EDIT_FLATFEE = "Edit Flatfee";
export const RULES = "Rules";
export const VALUES = "Values";
export const ROWS_PER_PAGE = "Rows per page";
export const ALL_LIST = "All list";
export const OF = "Of";
export const YOUR_DATA_HAS_BEEN_CHANGED = "Your data has been changed";
export const ARE_YOU_SURE = "Are you sure?";
export const YOU_WONT_BE_ABLE_TO_REVERT_THIS =
  "You won't be able to revert this!";
export const YES_CHANGE_IT = "Yes, change it!";
export const ESTIMATE = "Estimate";
export const MY_ESTIMATE = "My estimate";
export const CREATE_ESTIMATE = "Create estimate";
export const SERVICES = "Services";

export const ESTIMATE_DETAILS = "Estimate details";

export const PRODUCT = "Product";
export const SERVICE = "Service";

export const PRICE_HT = "Price ht";
export const VAT = "VAT";
export const PRICE_TTC = "Price ttc";

export const RGPD = "RGPD";

export const MARTERIAL_ISSUES = "Material issues";

export const FINISH = "Finish";
export const VALIDATE = "Validate";

export const MY_RAW_MARTERIAL_ISSUES = "My raw material issues";
export const EDIT_MARTERIAL_ISSUES = "Edit material issues";
export const CREATE_MARTERIAL_ISSUES = "Create material issues";
export const CREATE_BILL = "Create bill";
export const EDIT_BILLS = "Edit bills";

export const CONFIRM = "Confirm";

export const REMOVED_REQUESTS = "Removed Requests";
export const HISTORY_REQUESTS = "History Requests";
export const REQUIRED = "Required";

export const PDF = "PDF";
export const DEVIS = "DEVIS";

export const BILL = "Bill";

export const TOTAL = "Total";

export const TOTAL_PRICE_TND = "Prix total (TND)";
export const TOTAL_VAT_AMOUNT_TND = "Montant total de la TVA (TND)";
export const TOTAL_PRICE_WITH_VAT = "Prix total avec TVA (TND)";
export const VAT_PERCENTAGE = "TVA (%)";

export const CATEGORY = "Category";
export const DESCRIPTION = "Description";
export const QUANTITY = "Quantity";
export const UNITY_PRICE = "Unit Price";
export const WELLCOME_TO_TAXIORA_BACKOFFICE = "Wellcome to Taxiora Backoffice";

export const CHOOSE_YOUR_PRODUCT = "Choose your product";

export const MARTERIAL_SLIP = "Material slip";
export const MY_RAW_MARTERIAL_SLIP = "My raw material slip";

export const PRODUCT_STATUS = "Product status";

export const MY_ESTIMATIONS = "My estimate";

export const THERE_IS_NO_DATA_FOUND = "There is no data found";
export const DISTRIBUTORS_TREE = "Distributors tree";
export const STOCK_MOVEMENT = "Stock movement";
export const COUNT_STOCK_BY_CATEGORY = "Count stock by category";

export const THERE_IS_NO_HISTORY_FOUND = "There is no history found";

export const MY_RAW_MATERIAL_PRIMITIVES = "My raw material primitives";

export const MY_LEADS = "My leads";
export const LEADS = "Leads";

export const CREATE_LEADS = "Create leads";

export const DATABASE = "Database";

export const OWNER = "Owner";
export const ONLINE = "Online";
export const PHONE = "Phone";
export const GENERAL_DATA = "General data";

export const N_DRIVER = "N° driver";
export const TAXIMETER = "Taximeter";

export const PRO_CARD_GOT_AT = "Pro card got At";
export const PRO_CARD_EXPIRE_AT = "Pro card expire At";
// not translatede

export const EDIT_LEAD = "Edit lead";

export const SALES_VOUCHERS = "Sales vouchers";
export const MY_SALES_VOUCHERS = "My sales vouchers";
export const EDIT_SALES_VOUCHER = "Edit sales voucher";
export const CREATE_SALES_VOUCHER = "Create sales voucher";
export const CREATED_AT = "created At";
export const MY_RGPD = "My RGPD";
export const STOCK_RAW_MATERIAL = "Raw Materials";
export const STOCK_FINESHED_PRODUCT = "Finished Product";
export const TAXI_OWNER = "Taxi owner";
export const DRIVER = "Driver";
export const PERSONAL_INFO = "Personnel information";
export const TAXI_INFO = "Taxi information";
export const PROFESSIONAL_INFO = "Professional information";
export const LICENCE_INFO = "Licence information";
export const CLIENT_INFO = "Client information";
export const FULL_NAME = "Full name";
export const COMPANY = "Company";
export const REGISTRY = "Registry";
export const ADDRESS = "Address";
export const CITY = "City";
export const ZIP_CODE = "Zip code";
export const REGISRTY = "Regisrty";
export const ACTIVE = "Active";
export const SERIAL_NUMBER = "Serial number";
export const REGISTRATION_NUMBER = "Registration number";
export const MODEL_TAXI = "Model (taxi)";
export const BRAND_TAXI = "Brand (taxi)";
export const PLACES = "Places";
export const LICENCE_TYPE = "Licence type";
export const LICENCE = "Licence";
export const LICENCE_GOT_AT = "Licence got at";
export const LICENCE_EXPIRE_AT = "Licence expire at";
export const CIRCULATION_DATE = "Circulation date";
export const SETTINGS = "Settings";
export const PROFESSIONAL_CARD_INFO = "Professional card information";
export const EDIT_DRIVER = "Edit driver";
export const EDIT_TAXI = "Edit taxi";
export const CREATE_DRIVER = "Create driver";
export const CREATE_TAXI = "Create taxi";
export const PRICING = "Pricing";
export const PRICING_SERVICES = "Pricing services";
export const PRICING_PRODUCT = "Pricing products";
export const MY_SERVICES = "My services";
export const CREATE_SERVICE = "Create service";
export const EDIT_SERVICE = "Edit service";

export const CREATE_PRODUCT = "Create product";
export const EDIT_PRODUCT = "Edit product";

export const TERM_CONDITION = "Term condition";
export const PRIVACY_POLICY = "Privacy policy";
export const PRIMITIVES = "Primitives";
export const Category = "Category";
export const UPDATED_AT = "Updated at";
export const LABEL = "Label";
export const PRICE = "Price";
export const CREATE_TERM_CONDITION = "Create term condition";
export const EDIT_TERM_CONDITION = "Edit term condition";

export const QUESTIONS = "QuestionS";
export const ANSWERS = "Answers";
export const ACTIONS = "Actions";
export const CANCEL = "Cancel";
export const GO = "Go";

export const CREATE_PRIVACY_POLICY = "Create privacy policy";
export const EDIT_PRIVACY_POLICY = "Edit privacy policy";
export const CHECKSUM = "Checksum";
export const CODE_VERSION = "Code version";
export const PRIVATE_CHECKSUM = "Private checksum";
export const EXTERNAL = "External";
export const FILE = "File";
export const MODELS_OF_BRAND = "Models of brand";
export const PAUSE = "Pause";
export const TIME_ZONE = "Time zone";
export const LED = "LED";
export const OWNER_SHARE = "Owner share";

export const DEPOSIT = "Deposit";
export const TIME_SCALE = "Time scale";
export const REMAINIG_AMOUNT = "Remainig Amount";
export const ADD_PRODUCT = "Add product";
export const ADD_SERVICE = "Add service";
export const TTC = "TTC";
export const DISCOUNT = "Discount";
export const DATE_BEGIN = "Date begin";
export const DATE_END = "Date end";
export const SUBMIT = "Submit";
export const FILL_OUT_THIS_FIELDS = "Fill out this fields";
export const FINAL_PRICE = "Final price";
export const EDIT_ESTIMATE = "Edit estimate";

export const EDIT_BADGE = "Edit badge";
export const EDIT_BADGES = "Edit badges";

export const EDIT_BILL = "Edit bill";
export const CREATOR = "Creator";
export const SIMS = "SIMS";
export const CONFIRM_PAYMENT = "Confirm payment";
export const READY = "Ready";
export const DELIVER = "Deliver";
export const EXPLOITATION = "Exploitation";
export const GO_TO_TAXI_SHEET = "Go to taxi sheet";
export const EQUIPEMENT = "Equipement";
export const TASKS = "Tasks";
export const EXECUTOR = "Executor";
