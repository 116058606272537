import { ActionIcon } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import React from "react";

const DownloadPDF = ({ read: path }) => {
  const openInNewTab = () => {
    window.open(path, "_blank");
  };

  return (
    <ActionIcon
      variant="filled"
      color="lime"
      aria-label="Read"
      onClick={openInNewTab}
    >
      <IconDownload />
    </ActionIcon>
  );
};

export default DownloadPDF;
