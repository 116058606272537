import DriversInfo from "pages/ReadClientPage.test/DriversInfo";
import React from "react";

const UsersComp = ({ data }) => {
  console.log(data);
  return <DriversInfo data={data} />;

  return <div>UsersComp</div>;
};

export default UsersComp;
