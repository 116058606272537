import { Button } from "@mantine/core";
import { IconReload } from "@tabler/icons-react";
import React from "react";
import { toggleRefresh } from "../../redux/searchLink";
import { useDispatch } from "react-redux";
import { REFRESH } from "Constant";
import Translate from "Components/Translate";

const RefreshBtn = () => {
  const dispatch = useDispatch();

  const refreshPage = () => {
    dispatch(toggleRefresh());
  };

  return (
    <Button
      leftSection={<IconReload size={14} />}
      variant="default"
      onClick={() => refreshPage()}
    >
      <Translate>{REFRESH}</Translate>
    </Button>
  );
};

export default RefreshBtn;
