import {
  FileText,
  Box,
  Users,
  Heart,
  Server,
  Globe,
  UserCheck,
  Smile,
  UserPlus,
  Menu,
  MinusSquare,
  Cpu,
  Tool,
  Truck,
  Target,
  Database,
  Briefcase,
  Filter,
  GitPullRequest,
  DollarSign,
  Anchor,
} from "react-feather";

import {
  ADMINISTRATION,
  BILLS,
  CLIENT,
  COLLABORATOR,
  COMMERCIAL,
  DELIVARY_FORM,
  DISTRIBUTORS,
  ESTIMATE,
  FAQ,
  FINISHED_PRODUCTS,
  INVENTORY,
  LEADS,
  MARTERIAL_ISSUES,
  PRODUCTION,
  PURCHASE_ORDER,
  PURCHASE_REQUESTS,
  RAW_MATERIAL,
  RGPD,
  SALES,
  SHRING,
  STATISTICS,
  STOCK,
  STOCKS,
  WORK_ORDER,
  DATABASE,
  SERVICES,
  SALES_VOUCHERS,
  STOCK_RAW_MATERIAL,
  STOCK_FINESHED_PRODUCT,
  MY_RAW_MATERIAL_PRIMITIVES,
  PRICING,
  PRICING_SERVICES,
  PRICING_PRODUCT,
  PRIMITIVES,
  EXPLOITATION,
  TAXIS,
} from "Constant";
import InventoryIcon from "@mui/icons-material/Inventory";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import {
  IconBuildingCommunity,
  IconCar,
  IconCoin,
  IconParkingCircle,
  IconShoppingBag,
  IconUsersGroup,
} from "@tabler/icons-react";

export const COMMERCIAL_ITEMS = {
  menutitle: COMMERCIAL,
  Items: [
    // {
    //   path: "/collaborator",
    //   icon: IconUsersGroup,
    //   type: "link",
    //   title: COLLABORATOR,
    // },
    // {
    //   path: "/distributor",
    //   icon: IconBuildingCommunity,
    //   type: "link",
    //   title: DISTRIBUTORS,
    // },
    // {
    //   path: "/leads",
    //   icon: UserPlus,
    //   type: "link",
    //   title: LEADS,
    // },
    {
      path: "/client",
      icon: UserPlus,
      type: "link",
      title: CLIENT,
    },
    {
      path: "/purchase-order",
      icon: IconParkingCircle,
      type: "link",
      title: PURCHASE_ORDER,
    },

    {
      path: "/delivery-form",
      icon: Truck,
      type: "link",
      title: DELIVARY_FORM,
    },

    {
      path: "/bill",
      icon: FileText,
      type: "link",
      title: BILLS,
    },
    // {
    //   path: "/sales-vouchers",
    //   icon: IconShoppingBag,
    //   type: "link",
    //   title: SALES_VOUCHERS,
    // },
    {
      path: "/estimate",
      icon: Filter,
      type: "link",
      title: ESTIMATE,
    },
    {
      title: PRICING,
      icon: DollarSign,
      type: "sub",
      children: [
        {
          path: "/pricing-services",
          title: PRICING_SERVICES,
          type: "link",
        },
        {
          path: "/pricing-product",
          title: PRICING_PRODUCT,
          type: "link",
        },
      ],
    },
  ],
};

export const EXPLOITATION_ITEMS = {
  menutitle: EXPLOITATION,
  Items: [
    {
      path: "/taxis",
      icon: IconCar,
      type: "link",
      title: TAXIS,
    },
  ],
};

export const PRODUCTION_ITEMS = {
  menutitle: PRODUCTION,
  Items: [
    {
      path: "/work-order",
      icon: Tool,
      type: "link",
      title: WORK_ORDER,
    },
    {
      path: "/primitives",
      icon: Anchor,
      type: "link",
      title: PRIMITIVES,
    },
  ],
};

export const STOCK_ITEMS = {
  menutitle: STOCK,
  Items: [
    {
      title: RAW_MATERIAL,
      icon: MinusSquare,
      type: "sub",
      children: [
        {
          path: "/purchase-requests",
          title: PURCHASE_REQUESTS,
          type: "link",
        },
        {
          path: "/raw-material-stocks",
          title: STOCKS,
          type: "link",
        },
        {
          path: "/raw-material-inventory",
          title: INVENTORY,
          type: "link",
        },

        {
          path: "/raw-material-issues",
          title: MARTERIAL_ISSUES,
          type: "link",
        },
        // {
        //   path: "/raw-material-primitives",
        //   title: MY_RAW_MATERIAL_PRIMITIVES,
        //   type: "link",
        // },

        // {
        //   path: "/raw-material-slip",
        //   title: MARTERIAL_SLIP,
        //   type: "link",
        // },
      ],
    },
    {
      title: FINISHED_PRODUCTS,
      icon: Menu,
      type: "sub",
      children: [
        {
          path: "/finished-products-stocks",
          title: STOCKS,
          type: "link",
        },
        {
          path: "/finished-products-inventory",
          title: INVENTORY,
          type: "link",
        },
      ],
    },
  ],
};

export const ADMINISTRATION_ITEMS = {
  menutitle: ADMINISTRATION,
  Items: [
    {
      path: "/users",
      icon: Users,
      type: "link",
      title: "Users",
    },
    {
      path: "/polygons",
      icon: Heart,
      type: "link",
      title: "Polygons",
    },
    {
      path: "/parameter",
      icon: Heart,
      type: "link",
      title: "Parameter",
    },
    {
      path: "/wording",
      icon: Globe,
      type: "link",
      title: "Wording",
    },
    {
      path: "/profiles",
      icon: UserCheck,
      type: "link",
      title: "Profiles",
    },
    {
      path: "/services-list",
      icon: Server,
      type: "link",
      title: SERVICES,
    },
    {
      path: "/brands",
      icon: Box,
      type: "link",
      title: "Brands",
    },
    {
      path: "/firmware",
      icon: Cpu,
      type: "link",
      title: "Firmware",
    },
    {
      path: "/Holidays",
      icon: Smile,
      type: "link",
      title: "Holidays",
    },
    {
      path: "/faq",
      icon: FileText,
      type: "link",
      title: FAQ,
    },

    {
      path: "/RGPD",
      icon: Target,
      type: "link",
      title: RGPD,
    },

    // {
    //   path: "/bon-de-command",
    //   icon: ShoppingBag,
    //   type: "link",
    //   title: "Bon de command",
    // },
  ],
};

export const DATABASE_ITEMS = {
  menutitle: DATABASE,
  Items: [
    {
      path: "/statistics-requests",
      icon: GitPullRequest,
      type: "link",
      title: "SQL Query",
    },
    {
      path: "/Table-list/:TableName",
      icon: Database,
      type: "link",
      title: "Tables",
    },
  ],
};

export const STATISTICS_ITEMS = {
  menutitle: STATISTICS,
  Items: [
    {
      path: "/statistics-stock-Raw-Material",
      icon: InventoryIcon,
      type: "link",
      title: STOCK_RAW_MATERIAL,
    },
    {
      path: "/statistics-fp",
      icon: WarehouseIcon,
      type: "link",
      title: STOCK_FINESHED_PRODUCT,
    },
    {
      path: "/statistics-sales",
      icon: CurrencyExchangeIcon,
      type: "link",
      title: SALES,
    },
    // {
    //   path: "/statistics-marketing-passager",
    //   icon: UserPlus,
    //   type: "link",
    //   title: "marketing passager",
    // },
    {
      path: "/statistics-reservation",
      icon: Briefcase,
      type: "link",
      title: "reservation",
    },
    // {
    //   path: "/statistics-sharing",
    //   icon: UserPlus,
    //   type: "link",
    //   title: SHRING,
    // },
  ],
};
