import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import Breadcrumbs from "Components/Breadcrumbs";
import DataTableWithouPaggination from "Components/DataTables/DataTableWithouPaggination";

import { API, COLUMNS } from "./data";

import { MY_TAXIS } from "Constant";

const TaxisPage = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_TAXIS];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTableWithouPaggination
            columnSearch={false}
            newColumns={COLUMNS}
            link={list}
            createBtn={false}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default TaxisPage;
