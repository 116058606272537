import TaxisPage from "pages/TaxisPage";
const EXPLOITATION_ROUTES = [
  {
    path: "/taxis",
    element: <TaxisPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
];

export default EXPLOITATION_ROUTES;
