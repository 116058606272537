import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import Translate from "Components/Translate";

const SimpleTable = ({ requiredKeys }) => {
  // Transform the requiredKeys array into an object with title as keys and key as values
  const rowData = requiredKeys.reduce((acc, item) => {
    acc[item.title] = item.key;
    return acc;
  }, {});

  // Create the columns dynamically
  const columns = requiredKeys.map((item) => (
    <Column
      key={item.title}
      field={item.title}
      header={<Translate>{item.title}</Translate>}
    ></Column>
  ));

  return (
    <DataTable
      value={[rowData]}
      showGridlines
      tableStyle={{ minWidth: "50rem" }}
    >
      {columns}
    </DataTable>
  );
};

export default SimpleTable;
