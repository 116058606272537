import ActiveBtn from "../../../Components/DataTableBtns/ActiveBtn";
import CheckBox from "../../../Components/DataTableBtns/CheckBox";
import SelectAll from "../../../Components/DataTableBtns/SelectAll";
import { getTitleCodeLabel } from "../../../helper/getOptions";

// Urls
const CHECK_BADGE_FOR_SALE = (code) => `/back/badge/${code}/checksale`;
export const API = {
  list: "/back/badges",
  GET_BADGES_DRIVERS: "/back/badges/drivers",
  GET_BADGES_FITTERS: "/back/badges/fitters",
  Serial: "/back/badges/serials",
  create: "/back/badge",
  get: "/back/badge/",
  patch: "/back/badge/",
  CHECK_BADGE_FOR_SALE: CHECK_BADGE_FOR_SALE,
};

// Columns
export const COLUMNS = [
  {
    name: <SelectAll>generate all</SelectAll>,
    cell: (row) => <CheckBox rowCode={row.code} key={row.code} />,
    button: "true",
  },
  { name: "Status", selector: "status" },
  {
    name: "Number",
    selector: "serial_number",
  },
  { name: "Created at", selector: "created_at" },
  {
    name: "Active",
    cell: (row) => (
      <ActiveBtn patch={API.patch + row.code} state={row.active} />
    ),
    button: "true",
  },

  // {
  //   name: "Taxi",
  //   cell: (row) => <ReadPageBtn read={`/taxi/${row.code}`} />,
  //   button: "true",
  // },
  // {
  //   name: "Client",
  //   cell: (row) => <ReadPageBtn read={`/client/${row.code}`} />,
  //   button: "true",
  // },
  // {
  //   name: "Taximeter",
  //   cell: (row) => <ReadPageBtn read={`/taximeter/${row.code}`} />,
  //   button: "true",
  // },
];

// Search
export const SEARCH_ITEMS = [
  {
    name: "status",
    title: "status",
    inputForm: "SelectInput",
    options: [
      {
        value: "sold",
        label: "sold",
        name: "status",
      },
      {
        value: "in_stock",
        label: "in_stock",
        name: "status",
      },
      {
        value: "in_preparation",
        label: "in_preparation",
        name: "status",
      },
      {
        value: "under_examination",
        label: "under_examination",
        name: "status",
      },
      {
        value: "",
        label: "all",
        name: "status",
      },
    ],
  },
];
// Form
export const CREATE_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/badge",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "rfid",

    placeholder: "rfid",
    name: "rfid",
    inputForm: "SimpleInput",
  },
];
export const EDIT_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/badge",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "rfid",

    placeholder: "rfid",
    name: "rfid",
    inputForm: "SimpleInput",
  },
];
