import {
  Accordion,
  Avatar,
  Center,
  Divider,
  Group,
  Indicator,
  Paper,
  Space,
  Text,
} from "@mantine/core";
import React, { Fragment } from "react";
import { IconWifi } from "@tabler/icons-react";

import SimpleTable from "ComponentsMantine/SimpleTable";

import {
  CIRCULATION_DATE,
  DATE_BEGIN,
  DATE_END,
  LICENCE_EXPIRE_AT,
  LICENCE_GOT_AT,
  PLACES,
  SENSOR,
  SERIAL_NUMBER,
  TAXI,
  TAXI_INFO,
} from "Constant";

const ServicesComp = ({ data }) => {
  console.log(data);

  return (
    <Fragment>
      {data.map((service, i) => {
        const {
          code_service,
          label_service,
          active_service: active,
          date_begin_service,
          date_end_service,
        } = service;

        const title = label_service;

        const indicatorProps = active
          ? { color: "green", label: "Active" }
          : { color: "red", label: "Not active" };

        const requiredKeys = [
          { title: TAXI, key: title },

          { title: DATE_BEGIN, key: date_begin_service },
          { title: DATE_END, key: date_end_service },
        ];

        return (
          <Fragment key={i}>
            <Accordion
              chevronPosition="left"
              variant="contained"
              defaultValue={["client"]}
            >
              <Accordion.Item value={"client"} key={0}>
                <Center>
                  <Accordion.Control>
                    <Group wrap="nowrap">
                      <Indicator color={indicatorProps.color}>
                        <Avatar radius="xl" size="lg" color="orange">
                          {<IconWifi color="black" />}
                        </Avatar>
                      </Indicator>

                      <Space w="md" />

                      <div>
                        <Text size="xl" fw={700}>
                          {title}
                        </Text>
                        <Text size="sm" c="dimmed" fw={400}>
                          {/* {`phone number: ${user_phone} created at: ${created_at}`} */}
                        </Text>
                      </div>
                    </Group>
                  </Accordion.Control>
                  {/* <TaxiSettings data={data} /> */}
                  <Space w="lg" />
                </Center>

                <Accordion.Panel>
                  <Space h="md" />
                  <Paper shadow="xs" withBorder p="xl">
                    <Divider
                      my="xs"
                      label={<Text fw={700}> {TAXI_INFO} </Text>}
                      labelPosition="center"
                    />
                    <SimpleTable requiredKeys={requiredKeys} />
                  </Paper>
                  <Space h="md" />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default ServicesComp;
