import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { Tooltip, Menu, rem, ActionIcon } from "@mantine/core";
import { Award, PlusCircle } from "react-feather";

import {
  IconEdit,
  IconDots,
  IconAdjustments,
  IconEye,
} from "@tabler/icons-react";

import {
  CREATE_DRIVER,
  CREATE_TAXI,
  EDIT_CLIENT,
  EDIT_DRIVERS,
  EDIT_TAXI,
  GO_TO_TAXI_SHEET,
  SETTINGS,
} from "Constant";

import Translate from "Components/Translate";
import ActiveBtn from "./ActiveBtn";

import { useDispatch } from "react-redux";
import { changeModalType, changeTitle } from "../../redux/modal";
import { changeCurrentRow } from "../../redux/currentRow";
import { toggleRefresh } from "../../redux/searchLink";

import { Btn } from "AbstractElements";

export const ClientSettingsBtn = ({ data }) => {
  const dispatch = useDispatch();

  const { civility, firstname, lastname, code_client, client_active } = data;
  const fullName = `${civility}. ${firstname} ${lastname}`;

  const url = `/back/client/${code_client}`;
  const selectedRow = {
    getLink: url,
    putLink: url,
  };

  const handleClick = () => {
    dispatch(changeModalType("editClient"));
    dispatch(changeTitle(fullName));
    dispatch(changeCurrentRow(selectedRow));
  };

  const refresh = () => {
    dispatch(toggleRefresh());
  };

  return (
    <Fragment>
      <ActionIcon size="lg" variant="subtle" color="gray">
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Tooltip label={<Translate> {SETTINGS}</Translate>}>
              <IconDots color="gray" />
            </Tooltip>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              leftSection={
                <IconEdit style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={handleClick}
            >
              {EDIT_CLIENT}
            </Menu.Item>

            <Menu.Item
              leftSection={
                <IconAdjustments style={{ width: rem(14), height: rem(14) }} />
              }
            >
              <ActiveBtn state={client_active} patch={url} refresh={refresh} />
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </ActionIcon>
    </Fragment>
  );
};

export const DriverSettingsBtn = ({ data }) => {
  const dispatch = useDispatch();

  const {
    user_civility,
    user_firstname,
    user_lastname,
    code_user,
    user_active,
    usr_owner,
  } = data;
  const fullName = `${user_civility}. ${user_firstname} ${user_lastname}`;
  const url = `/back/user/${code_user}`;

  const selectedRow = {
    getLink: url,
    putLink: url,
  };

  const handleClick = () => {
    dispatch(changeModalType("editDriver"));
    dispatch(changeTitle(fullName));
    dispatch(changeCurrentRow(selectedRow));
  };

  const refresh = () => {
    dispatch(toggleRefresh());
  };

  return (
    <Fragment>
      <ActionIcon size="lg" variant="subtle" color="gray">
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Tooltip label={<Translate> {SETTINGS}</Translate>}>
              <IconDots color="gray" />
            </Tooltip>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              leftSection={
                <IconEdit style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={handleClick}
            >
              {EDIT_DRIVERS}
            </Menu.Item>

            {!usr_owner && (
              <Menu.Item
                leftSection={
                  <IconAdjustments
                    style={{ width: rem(14), height: rem(14) }}
                  />
                }
              >
                <ActiveBtn state={user_active} patch={url} refresh={refresh} />
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Menu>
      </ActionIcon>
    </Fragment>
  );
};

export const TaxisSettingsBtn = ({ data }) => {
  const dispatch = useDispatch();
  const { code } = useParams();

  const { taxi_serial_number, code_taxi, active_taxi } = data;
  const url = `/back/client/${code}/taxi/${code_taxi}`;
  const selectedRow = {
    getLink: url,
    putLink: url,
  };

  const handleClick = () => {
    dispatch(changeModalType("editTaxi"));
    dispatch(changeTitle(taxi_serial_number));
    dispatch(changeCurrentRow(selectedRow));
  };

  const refresh = () => {
    dispatch(toggleRefresh());
  };
  return (
    <Fragment>
      <ActionIcon size="lg" variant="subtle" color="gray">
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Tooltip label={<Translate> {SETTINGS}</Translate>}>
              <IconDots color="gray" />
            </Tooltip>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              leftSection={
                <IconEye style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => {
                const path = `/taxi/${code}`;
                window.open(path, "_blank", "noopener,noreferrer");
              }}
            >
              <Translate>{GO_TO_TAXI_SHEET}</Translate>
            </Menu.Item>

            <Menu.Item
              leftSection={
                <IconEdit style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={handleClick}
            >
              <Translate>{EDIT_TAXI}</Translate>
            </Menu.Item>

            <Menu.Item
              leftSection={
                <IconAdjustments style={{ width: rem(14), height: rem(14) }} />
              }
            >
              <ActiveBtn state={active_taxi} patch={url} refresh={refresh} />
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </ActionIcon>
    </Fragment>
  );
};

export const CreateDriver = () => {
  const dispatch = useDispatch();

  const changeState = (type) => {
    dispatch(changeModalType(type));
  };
  return (
    <Btn
      attrBtn={{
        onClick: () => changeState("createDriver"),
        color: "success",
        className: "btn btn-success btn-sm",
      }}
    >
      <PlusCircle />
      <Translate>{CREATE_DRIVER}</Translate>
    </Btn>
  );
};

export const CreateTaxi = () => {
  const dispatch = useDispatch();

  const changeState = (type) => {
    dispatch(changeModalType(type));
  };
  return (
    <Btn
      attrBtn={{
        onClick: () => changeState("createTaxi"),
        color: "success",
        className: "btn btn-success btn-sm",
      }}
    >
      <PlusCircle />
      <Translate>{CREATE_TAXI}</Translate>
    </Btn>
  );
};
