import {
  Accordion,
  Avatar,
  Center,
  Divider,
  Group,
  Indicator,
  Paper,
  Space,
  Text,
} from "@mantine/core";
import React, { Fragment } from "react";
import { IconCar } from "@tabler/icons-react";

import SimpleTable from "ComponentsMantine/SimpleTable";

import {
  CIRCULATION_DATE,
  COMPANY,
  LICENCE_EXPIRE_AT,
  LICENCE_GOT_AT,
  PHONE,
  PLACES,
  REGISRTY,
  SENSOR,
  SERIAL_NUMBER,
  TAXI,
  TAXI_INFO,
} from "Constant";
import ClientInfo from "pages/ReadClientPage.test/ClientInfo";

const ClientComp = ({ data }) => {
  return <ClientInfo data={data} />;

  const {
    company,
    civility,
    firstname,
    lastname,
    phone,
    registry,
    address,
    city,
    zip_code,
    client_active: active,
    complete,
    pro_card,
    pro_card_got_at,
    pro_card_expire_at,
  } = data;

  const title = `${civility} ${firstname} ${lastname}`;

  const indicatorProps = active
    ? { color: "green", label: "Active" }
    : { color: "red", label: "Not active" };

  const requiredKeys = [
    { title: COMPANY, key: company },

    { title: PHONE, key: phone },
    { title: REGISRTY, key: registry },
  ];
  return (
    <Fragment>
      <Accordion
        chevronPosition="left"
        variant="contained"
        defaultValue={["client"]}
      >
        <Accordion.Item value={"client"} key={0}>
          <Center>
            <Accordion.Control>
              <Group wrap="nowrap">
                <Indicator color={indicatorProps.color}>
                  <Avatar radius="xl" size="lg" color="orange">
                    {<IconCar color="black" />}
                  </Avatar>
                </Indicator>

                <Space w="md" />

                <div>
                  <Text size="xl" fw={700}>
                    {title}
                  </Text>
                  <Text size="sm" c="dimmed" fw={400}>
                    {/* {`phone number: ${user_phone} created at: ${created_at}`} */}
                  </Text>
                </div>
              </Group>
            </Accordion.Control>
            {/* <TaxiSettings data={data} /> */}
            <Space w="lg" />
          </Center>

          <Accordion.Panel>
            <Space h="md" />
            <Paper shadow="xs" withBorder p="xl">
              <Divider
                my="xs"
                label={<Text fw={700}> {TAXI_INFO} </Text>}
                labelPosition="center"
              />
              <SimpleTable requiredKeys={requiredKeys} />
            </Paper>
            <Space h="md" />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Fragment>
  );
};

export default ClientComp;
